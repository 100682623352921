<template>
  <div>
      <b-loading :is-full-page="true" :active="(questionCategories||[]).length===0"></b-loading>
      <div
          class="card is-shadowless is-bordered"
          style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px;"
      >
        <div v-if="!isQuizStarted&&!isQuizFinished">
          <div class="columns">
            <div class="column">
              <b-field :label="$tc('quiz.categories')">
                <div v-for="category in questionCategories" :key="category.category">
                  <b-checkbox v-model="checkedCategories" :native-value="category.category">{{
                      category.category
                    }}
                  </b-checkbox>
                  <br/>
                </div>
              </b-field>
            </div>
            <div class="column">
              <b-field :label="$tc('quiz.questionCount')">
                <b-numberinput :placeholder="$tc('quiz.questionCount')" class="control" v-model="quizQuestionsCount"/>
              </b-field>
            </div>
          </div>
          <button class="button is-medium is-success" @click="startQuiz">{{ $t('quiz.startQuiz') }}</button>
        </div>
        <div style="margin-top: -18px; padding-top: 16px" v-if="isQuizStarted&&!isQuizFinished">
           <span style="float: right; margin-right: 8px; font-size: 20px;"
                 class="has-text-weight-normal mobile-visible"
                 v-if="isQuizStarted&&!isQuizFinished">{{ $t('quiz.question') }} {{
               quizQuestionsIndex + 1
             }}/{{ quizQuestions.length }}</span>
          <b-button
              class="is-small"
              rounded
              style="float: right"
              :disabled="quizQuestionsIndex + 1 === quizQuestions.length || quizQuestions[quizQuestionsIndex].answer.length===0"
              @click="quizQuestionsIndex++"
              v-if="isQuizStarted&&!isQuizFinished"
          >{{ $t('quiz.nextQuestion') }}
          </b-button>
          <span style="float: right; margin-right: 8px; font-size: 20px;"
                class="has-text-weight-normal desktop-visible"
                v-if="isQuizStarted&&!isQuizFinished">{{ $t('quiz.question') }} {{
              quizQuestionsIndex + 1
            }}/{{ quizQuestions.length }}</span>
          <b-button
              class="is-small mr-2  previous-question-button"
              rounded
              style="float: right"
              @click="quizQuestionsIndex--"
              :disabled="quizQuestionsIndex === 0"
              v-if="isQuizStarted&& !isQuizFinished"
          >{{ $t('quiz.previousQuestion') }}
          </b-button>
          <div
              style="margin-bottom: 8px; padding-top: 8px; font-style: italic; text-align: justify; text-justify: inter-word; margin-right: 16px;">
            {{ quizQuestions[quizQuestionsIndex].question_text }}
          </div>
          <div v-for="opt in ['a', 'b', 'c', 'd']"
               :class="{card:true, 'bumped-on-hover':true,'answer-box':true, 'answer-box-selected':quizQuestions[quizQuestionsIndex].answer[opt]}"
               @click="changeAnswer(quizQuestionsIndex, opt)">{{
              opt.toUpperCase()
            }}. {{ quizQuestions[quizQuestionsIndex]['answer_' + opt + '_text'] }}
          </div>
          <div class="has-text-centered" style="margin-top: 24px;" v-if="quizQuestionsIndex+1===quizQuestions.length">
            <button class="button is-medium is-success" @click="isQuizFinished=true"
                    :disabled="quizQuestions[quizQuestions.length-1].answer.length===0">{{ $t('quiz.finishQuiz') }}
            </button>
          </div>
        </div>
        <div v-if="isQuizStarted&&isQuizFinished">
          <b-button
              class="is-small mr-2"
              rounded
              style="float: right"
              @click="resetQuiz"
          >{{ $t('quiz.restartQuiz') }}
          </b-button
          >
          <span
              class="subtitle is-4">{{ $t('quiz.result') }}: {{ correctAnswersCount }}/{{
              quizQuestions.length
            }} ({{ Math.round(correctAnswersCount / quizQuestions.length * 100) }}%)</span>
          <div v-for="question in quizQuestions">
            <div style="margin-bottom: 8px; padding-top: 16px; font-style: italic">
              {{ question.question_text }}
            </div>
            <div v-for="opt in ['a', 'b', 'c', 'd']"
                 :class="{card:true, 'bumped-on-hover':true,'answer-box':true,
                 'answer-box-correct':question[`answer_${opt}_status`]===1&&question.answer[opt],
                 'answer-box-error':(question[`answer_${opt}_status`]===0&&question.answer[opt]),
                 'answer-box-selected': (question[`answer_${opt}_status`]===1 && !question.answer[opt]),
                 }">
              {{
                opt.toUpperCase()
              }}. {{ question['answer_' + opt + '_text'] }}
            </div>
            <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger>
                <b-button
                    :label="$tc('quiz.explanation')"
                    type="is-primary is-small"
                    aria-controls="contentIdForA11y1"/>
              </template>
              <div class="notification">
                <div class="content">
                  <p v-html="question.explanation" v-if="question.explanation!=null && question.explanation.length>0">
                  </p>
                  <p v-else>
                    {{ $t('quiz.noExplanation') }}
                  </p>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";

export default {
  name: "Quiz",
  components: {HomeWrapper},
  data() {
    return {
      questionCategories: [],
      checkedCategories: [],
      quizQuestions: [],
      quizQuestionsCount: 10,
      quizQuestionsIndex: 0,
      isQuizStarted: false,
      isQuizFinished: false,
    };
  },
  computed: {
    correctAnswersCount() {
      let count = 0;
      for (const question of this.quizQuestions) {
        if (question[`answer_a_status`] === (question.answer['a'] ? 1 : 0)
            && question[`answer_b_status`] === (question.answer['b'] ? 1 : 0)
            && question[`answer_c_status`] === (question.answer['c'] ? 1 : 0)
            && question[`answer_d_status`] === (question.answer['d'] ? 1 : 0)) {
          count++;
        }
      }
      return count;
    },
  },
  async mounted() {
    const profile = await this.$api.getProfile();
    this.questionCategories = (await this.$api.getQuestionCategories()).filter(x => {
      return profile.is_inspector || profile.quiz_categories.includes(x.category.split('/')[0]) || profile.quiz_categories.includes(x.category);
    });
  },
  methods: {
    changeAnswer(quizQuestionsIndex, opt) {
      this.quizQuestions[quizQuestionsIndex].answer[opt] = !this.quizQuestions[quizQuestionsIndex].answer[opt];
    },
    async startQuiz() {
      this.quizQuestions = (await this.$api.getQuestionsByCategories(this.checkedCategories, this.quizQuestionsCount)).map(q => {
        q.answer = {};
        for (const opt of ['a', 'b', 'c', 'd'])
          q.answer[opt] = false;
        return q;
      });
      this.quizQuestionsIndex = 0;
      this.isQuizStarted = true;
    },
    resetQuiz() {
      this.quizQuestionsIndex = 0;
      this.isQuizStarted = false;
      this.isQuizFinished = false;
      window.location.reload();
    }
  }
}
</script>

<style scoped>

.bumped-on-hover:hover {
  filter: brightness(90%);
}

.answer-box {
  margin-bottom: 8px;
  padding: 8px;
  cursor: pointer;
}

.answer-box-correct {
  background: rgba(72, 199, 142, 0.8);
  overflow: hidden;
  z-index: 2;
}

.answer-box-error {
  background: rgba(241, 70, 104, 0.8);
  overflow: hidden;
  z-index: 2;
}

.answer-box-selected {
  background: rgba(52, 115, 220, 0.8);
  overflow: hidden;
  z-index: 2;
}

@media screen and (min-width: 600px) {
  .mobile-visible {
    display: none;
  }

  .desktop-visible {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .mobile-visible {
    display: block;
  }

  .desktop-visible {
    display: none;
  }

  .previous-question-button {
    float: left !important;
  }
}
</style>
